import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';
import List from '../common/List';
import Filter from '../common/Filter';
import DataPoolRow from './DataPoolRow';

const optionText = choice => choice.name ? choice.name : '';

const DataPoolFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const DataPoolList = props => (
    <List {...props} filters={<DataPoolFilter />} perPage={50} filterDefaultValues={filterDefaultValues} exporter={false}>
        <DataPoolRow />
    </List>
);

export default DataPoolList;