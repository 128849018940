import React, { useState, useEffect } from 'react';
import { Title, Link, List, useTranslate, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import IconCard from '../common/IconCard';
import TicketCreateButton from '../tickets/TicketCreateButton';
import PackageRow from '../packages/PackageRow';
import UsageGraph from './UsageGraph';
import StarLinkUsageGraph from './StarLinkUsageGraph';

import sites from '../sites';
import simcards from '../simcards';
import devices from '../devices';
import starlink_terminals from '../starlink_terminals';
// import invoices from '../invoices';

import { fetchDashboardStats, getAccount } from '../../services/api';
import SiteList from './SiteList';


const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(3)
    },
    title: {
        fontSize: '50px',
        fontWeight: 500,
        color: theme.palette.primary.dark,
        marginTop: 0,
        marginBottom: '30px'
    },
    redCard: {
        backgroundColor: red[100]
    },
    redIcon:{
        backgroundColor: red[500]
    }
}));

const PENDING_PACKAGES_PROPS = {
    basePath: '/packages',
    resource: 'packages',
    actions: null,
    hasCreate: false,
    hasShow: false,
    hasEdit: false,
    filter: { status: 'provisioning' },
    empty: <></>,
    bulkActionButtons: false
};

const Dashboard = () => {
    const [stats, setStats] = useState();
    const [account, setAccount] = useState();
    const translate = useTranslate();
    const classes = useStyles();
    const { permissions } = usePermissions();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchDashboardStats();
            setStats(response);

            const responseAccount = await getAccount();
            setAccount(responseAccount);
        };

        fetchData(); // eslint-disable-line react-hooks/exhaustive-deps
    }, []);

    if (!account || !stats) {
        return null;
    }

    return (
        <>
            <Title title={account?.customer?.name} />
            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} >
                    <Box display="flex" justifyContent="flex-end">
                        <TicketCreateButton />
                    </Box>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/sites">
                            <IconCard title={translate('resources.sites.name', { smart_count: 2, _: 'Sites' })} value={stats?.sites?.total} icon={<sites.icon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/simpools">
                            <IconCard title={translate('resources.simpools.name', { smart_count: 2, _: 'SimPools' })} value={stats?.pools?.total} icon={<simcards.icon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/devices">
                            <IconCard title={translate('resources.devices.name', { smart_count: 2, _: 'Devices' })} value={stats?.devices?.total} icon={<devices.icon />} />
                        </Link>
                    </Grid>
                    {permissions?.includes('can_view_starlinks') && (
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            <Link to="/starlink_terminals">
                                <IconCard title={translate('resources.starlinks.name', { smart_count: 2, _: 'Starlinks' })} value={stats?.starlinks?.total} icon={<starlink_terminals.icon />} />
                            </Link>
                        </Grid>
                    )}
                    {/* {permissions?.includes('can_view_invoices') && (
                        <>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <Link to="/invoices">
                                    <IconCard title={translate('Amount due')} value={stats?.invoices?.amount_due} icon={<invoices.icon />} />
                                </Link>
                            </Grid>
                            <Grid item lg={4} sm={6} xl={4} xs={12}>
                                <Link to="/invoices">
                                    <IconCard title={translate('Amount over due')} value={stats?.invoices?.amount_over_due} icon={<invoices.icon />} classes={stats?.invoices?.amount_over_due > 0 ? { root: classes.redCard, iconWrapper: classes.redIcon } : null} />
                                </Link>
                            </Grid>
                        </>
                    )} */}
                </Grid>
                {permissions?.includes('can_view_packages') && (
                    <Grid item xs={12}>
                        <Card>
                            <List
                                {...PENDING_PACKAGES_PROPS}
                                filters={<><Box pt={3} paddingX={2}><Typography variant="h6">{translate('Pending packages')}</Typography></Box></>}
                                title=" "
                            >
                                <PackageRow />
                            </List>
                        </Card>
                    </Grid>
                )}
                <UsageGraph />
                <StarLinkUsageGraph />
                <SiteList />
            </Grid>
        </>
    );
};

export default Dashboard;