import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { useTranslate } from 'react-admin';

import ToggleButtonGroup, { ToggleButton } from '../common/ToggleButtonGroup';
import GraphTooltip from '../common/GraphTooltip';

import { getStarlinkUsageStats } from '../../services/api';


const CustomToolTip = ({ active, payload, label, freq }) => {
    if (!active || !payload) {
        return null;
    }

    let title = new Date(label).toLocaleString('default', { day: 'numeric', month: 'numeric', year: 'numeric' });
    if (freq === 'yearly') {
        title = new Date(label).getFullYear();
    }
    if (freq === 'monthly') {
        title = new Date(label).toLocaleDateString('default', { month: 'numeric', year: 'numeric' });
    }

    return (
        <GraphTooltip>
            <p><strong>{title}</strong></p>
            {payload.map((item, i) => (
                <p key={i}>{item.value} GB</p>
            ))}
        </GraphTooltip>
    );
};

const StarLinkUsageGraph = () => {
    const [freq, setFreq] = useState('monthly');
    const [data, setData] = useState();
    const translate = useTranslate();

    const fill = "#D65A31";

    const formatDate = (datetime) => {
        const date = new Date(datetime);

        if (freq === 'yearly') {
            return date.getFullYear();
        }
        if (freq === 'monthly') {
            return date.toLocaleString('default', { month: 'short' });
        }
        return date.toLocaleString('default', { day: 'numeric', month: 'numeric', year: 'numeric' });
    };

    useEffect(() => {
        const fetchData = async () => {
            const responseUsageStats = await getStarlinkUsageStats({ freq });
            setData(responseUsageStats.data || []);
        };

        fetchData(); // eslint-disable-line react-hooks/exhaustive-deps
    }, [freq]);

    return (
        <Grid item xs={12}>
            <Card>
                <Box padding={3}>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                        <Box marginRight={3}>
                            <Typography variant="h6">{translate('StarLink data usage reports')}</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" flex="1">
                            <ToggleButtonGroup value={freq} setValue={setFreq}>
                                <ToggleButton value="daily">{translate('Daily')}</ToggleButton>
                                <ToggleButton value="monthly">{translate('Monthly')}</ToggleButton>
                                <ToggleButton value="yearly">{translate('Yearly')}</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Box mt={3}>
                        <ResponsiveContainer width="100%" height={450}>
                            <BarChart
                                data={data}
                                margin={{
                                    top: 50, right: 30, left: 20, bottom: 10,
                                }}
                            >
                                <CartesianGrid strokeDasharray="10 5" vertical={false} />
                                <XAxis dataKey="date" tickFormatter={formatDate} />
                                <YAxis dataKey="value" unit="GB" />
                                <Tooltip content={<CustomToolTip freq={freq} />} />
                                <Bar name="Data Usage" dataKey="value" fill={fill} radius={3} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
};

export default StarLinkUsageGraph;