import React from 'react';
import { Datagrid, NumberField, TextField, DateInput, SelectInput, Pagination, TextInput, useTranslate, ReferenceInput, AutocompleteInput } from 'react-admin';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import DateField from '../common/DateField';
import IconButton from '../common/IconButton';
import UsageReportGraph from './UsageReportGraph';

import { API_URL } from '../../services/settings';
import SplitDateButtons from '../common/SplitDateButtons';

const siteOptionText = choice => choice.name ? choice.name : '';
const simPoolOptionText = choice => choice.name ? choice.name : '';

const UsageReportListActions = (props) => {
	const translate = useTranslate();
	const params = new URLSearchParams();
	params.append('format', 'xls');
	for (const key in props.filterValues) {
		params.append(key, props.filterValues[key]);
	}

	return (
		<ListActions {...props}>
			<IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/export/simonly.xls?${params.toString()}`}>{translate('Export data SimPool')}</IconButton>
			<IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/usages/report?${params.toString()}`}>{translate('Export all data')}</IconButton>
		</ListActions>
	);
};

const UsageFilter = (props) => {
	const translate = useTranslate();

	const groupByChoices = [
		{ id: 'pool', name: translate('SimPool') },
		{ id: 'site', name: translate('Site') },
		{ id: 'device', name: translate('Device') },
		{ id: 'iccid_in_pool', name: translate('ICCID in SimPool') },
		{ id: 'iccid_in_device', name: translate('ICCID in Device') }
	];

	return (
		<Filter {...props}>
            <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
				<AutocompleteInput optionText={siteOptionText} />
			</ReferenceInput>
			<ReferenceInput label="SimPool" source="sim_pool_id" reference="simpools" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
				<AutocompleteInput optionText={simPoolOptionText} />
			</ReferenceInput>
			<TextInput source="iccid" alwaysOn />
			<SelectInput source="group_by" choices={groupByChoices} alwaysOn />
			<DateInput source="start_date" alwaysOn />
			<DateInput source="end_date" alwaysOn />
			<SplitDateButtons source="date" alwaysOn />
		</Filter>
	);
};

const UsageReportRow = ({ data, ...props }) => {
	const translate = useTranslate();
	const firstRecord = data[0];

	if (!firstRecord) {
		return null;
	}

	const records = Object.values(data).slice(0, props.total);

	return (
		<>
			<Typography variant="h6" align="right" style={{ padding: '10px' }}>{translate('Total')}: {firstRecord.total_usage} GB</Typography>
			<UsageReportGraph data={records} groupBy={props.filterValues.group_by} />
			<Datagrid {...props} data={data}>
				{'site' in firstRecord && <TextField source="site" />}
				{'pool' in firstRecord && <TextField source="pool" />}
				{'device' in firstRecord && <TextField source="device" />}
				{'iccid' in firstRecord && <TextField source="iccid" />}
				<DateField source="start_date" />
				<DateField source="end_date" />
				<NumberField label="GB" source="usage" options={{ maximumFractionDigits: 2 }} />
				<NumberField label="€" source="price" options={{ style: 'currency', currency: 'eur', maximumFractionDigits: 2 }} />
			</Datagrid>
		</>
	);
};

const UsageReportPagination = (props) => (
	<Pagination {...props} rowsPerPage={props.total} rowsPerPageOptions={[props.total]} />
);

const UsageReportList = ({ staticContext, ...props }) => (
	<List {...props}>
		<UsageReportRow />
	</List>
);

UsageReportList.defaultProps = {
	title: "Usage reports",
	resource: "usages/report",
	basePath: "usage/report",
	hasList: true,
	hasShow: false,
	hasCreate: false,
	hasEdit: false,
	filters: <UsageFilter />,
	filterDefaultValues: { start_date: moment().startOf('month').format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD'), group_by: 'pool' },
	bulkActionButtons: false,
	actions: <UsageReportListActions />,
	exporter: false,
	pagination: <UsageReportPagination />
};

export default UsageReportList;
