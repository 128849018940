import React from 'react';
import { useLocale, useTranslate } from 'react-admin';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import 'moment/min/locales';

const HumanDateField = ({
    className,
    source,
    record = {},
    basePath,
    ...rest
}) => {
    const locale = useLocale();
    const translate = useTranslate();
    const momentValue = moment(get(record, source));
    if (!get(record, source)) {
        return <></>
    }
    const now = moment.now()
    const value = momentValue.isValid() ? moment.duration(momentValue-now).lang(locale).humanize(true): translate('Never');

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...rest}
        >
            {value && typeof value !== 'string' ? JSON.stringify(value) : value}
        </Typography>
    );
};

export default HumanDateField;