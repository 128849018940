import SimCard from '@material-ui/icons/SimCard';

import DataPoolList from './DataPoolList';
import DataPoolShow from './DataPoolShow';

export default {
	list: DataPoolList,
	show: DataPoolShow,
	icon: SimCard
};
