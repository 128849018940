import React from 'react';
import { TabbedShowLayout, Tab, SimpleShowLayout, ArrayField, ReferenceField, TextField, NumberField, ReferenceArrayField, ReferenceManyField, Pagination, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import DeviceBandwidthGraph from '../common/DeviceBandwidthGraph';
import MapComponent from '../common/MapComponent';
import DateField from '../common/DateField';
import WanRow from '../wans/WanRow';
import OnlineStatusField from './fields/OnlineStatusField';
import DeviceActionsButtons from './DeviceActionsButtons';
import LineRow from '../lines/LineRow';
import LocationUpdateRow from '../location_updates/LocationUpdateRow';

const DeviceTitle = ({ record }) => {
	const translate = useTranslate();
	const serial = record.serial ? record.serial.split('-').pop() : '';
	const title = translate('resources.devices.name', { smart_count: 1, _: 'Device' });

	return (
		<>
			<Helmet>
				<title>{`KB - ${title}[${serial} - ${record.name}] ${record.site ? '- ' + record.site.name : ''}`}</title>
			</Helmet>
			<span>{record ? `${record.name} ` : ''}</span>
		</>
	);
};

const DeviceActions = (props) => {
	return (
		<ShowActions {...props}>
			<DeviceActionsButtons record={props.data} />
		</ShowActions>
	);
};

const Content = (props) => {
	const translate = useTranslate();

	return (
		<Grid container>
			<Grid item md={6}>
				<SimpleShowLayout {...props}>
					<TextField source="serial" />
					<OnlineStatusField />
					<TextField label="Product" source="product_name" />
					<ReferenceField label="Site" source="site.id" reference="sites" link="show" allowEmpty>
						<TextField source="name" />
					</ReferenceField>
					<TextField source="address" />
					<NumberField source="data_limit" label="Data Limit Alert (GB)" />
					<TextField source="data_limit_contacts" />
					<TextField source="customer_notes" label="Notes" />
					<DateField label="Last Online at" source="last_online" showTime />
					<DateField label="Last Offline at" source="offline_at" showTime />
				</SimpleShowLayout>
			</Grid>
			<Grid item md={6}>
				<MapComponent {...props} />
			</Grid>
			<Grid item md={12}>
				{(props.record && props.record.wans?.length > 0) && (
					<>
						<Typography variant="h6">Wans</Typography>
						<SimpleShowLayout {...props}>
							<ArrayField source="wans" addLabel={false} style={{ marginBottom: '16px' }}>
								<WanRow expanded />
							</ArrayField>
						</SimpleShowLayout>
					</>
				)}
				{(props.record && props.record.lines?.length > 0) && (
					<>
						<Typography variant="h6">{translate('Fixed lines')}</Typography>
						<SimpleShowLayout {...props}>
							<ReferenceArrayField source="lines" reference="lines" addLabel={false}>
								<LineRow exclude={['device']} />
							</ReferenceArrayField>
						</SimpleShowLayout>
					</>
				)}
			</Grid>
		</Grid>
	);
};

const Tabs = props => (
	<TabbedShowLayout {...props}>
		<Tab label="summary">
			<Content />
			<DeviceBandwidthGraph />
			<ReferenceManyField addLabel={false} reference="location_updates" target="device_id" pagination={<Pagination />} sort={{ field: 'timestamp', order: 'DESC' }}>
				<LocationUpdateRow />
			</ReferenceManyField>
		</Tab>
	</TabbedShowLayout>
);

const DeviceShow = (props) => (
	<Show title={<DeviceTitle />} actions={<DeviceActions {...props} />} {...props}>
		<Tabs />
	</Show>
);

export default DeviceShow;
